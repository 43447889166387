.App {
    max-width: 96rem;
    padding: 2rem;
    color: #000;
}

img {
    height: 60px;
}

p {
    margin: 0;
}

.style_l {
    font-weight: 900;
    font-size: 2.45rem;
    margin-top: 3rem;
}

.style_l2 {
    font-weight: 900;
}

.style_s {
    margin-top: 1.5rem;
    max-width: 450px;
    line-height: 1.4;
    font-size: 1.25rem;
}

.style_s2 {
    font-weight: 900;
    text-transform: uppercase;
    padding-top: 0.12em;
    color: #000;
    font-size: 1.75rem;
    position: relative;
    text-decoration: none;
}

.style_s2::before {
    display: block;
    content: "";
    margin-top: 1rem;
}

.style_s2::after {
    position: absolute;
    border: 0.25rem solid #e0245e;
    content: "";
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    box-sizing: border-box;
}

.style_s2:hover {
    background-color: #e0245e;
    color: #fff;
}

nav {
    margin-top: 3rem;
}

@media (min-width: 350px) {
    .style_l, .style_l2 {
        font-size: 2.95rem;
    }
}

@media (min-width: 35em) {
    .style_l, .style_l2 {
        font-size: 3.35rem;
    }
    .style_s {
        font-size: 1.35rem;
    }
    .style_s2 {
        font-size: 1.8rem;
    }
} 